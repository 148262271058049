<script setup>
import ButtonSmall from '@/Components/Button/Small.vue';
import TinyDetailsCard from '@/Components/Flexer/TinyDetailsCard.vue';
import { Link } from '@inertiajs/vue3';
import { inject } from 'vue';

const route = inject('route');
defineProps({
    flexer: Object,
});
const emits = defineEmits(['buttonClicked']);
</script>
<template>
    <div class="flex items-center justify-between w-full gap-4 px-8 py-2 bg-white rounded min-h-12">
        <img src="@/../img/heart.svg" alt="" />
        <Link :href="route('location.flexers.show', { id: flexer.id })" class="flex-1">
            <TinyDetailsCard image :flexer="flexer" />
        </Link>
        <div class="w-35">
            <ButtonSmall white @click="emits('buttonClicked')">
                <div class="w-full text-center">{{ $t('Not implemented yet') }}</div>
            </ButtonSmall>
        </div>
    </div>
</template>
